import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Modal, Row, Col, Form, Button } from "react-bootstrap"



function AssessmentModal(props) {
    const [message, setMessage] = useState("")
  const { register, handleSubmit, formState, reset } = useForm()
  //const onSubmit = data => console.log(data)

  const submitForm = data => 
  fetch('https://5tcubstq97.execute-api.us-west-2.amazonaws.com/production/assessment-form', {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: 'POST',
      body: JSON.stringify(data)
})
.then((response) => {
  if (response.status === 200) {
      console.log(JSON.stringify(data));
      setMessage('Message Sent!');
      reset();
  } else {
      console.log('network error')
  }
})        
setTimeout((data) => { 
  //alert(JSON.stringify(values, null, 2));
  // actions.resetForm();
  // actions.setSubmitting(false);
}, 400);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="homeModal"
    >
      <Modal.Header closeButton style={{paddingBottom: 0}}>
        <Row>
          <Col>
            <div>
              <h3 className="assessmentHeader">Symphony Assessment</h3>
              <p className="modalBody">
                Tell Symphony about whether you, your loved one or your client
                is anxious or concerned about:
              </p>
            </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <Form onSubmit={handleSubmit(submitForm)} id="assessement-form">
          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                1. Feeling helpless to manage care after discharge?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="helpless"
                id="helpless-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="helpless"
                id="helpless-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                2. Inability to keep up with home routines, pets or maintenance?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="routines"
                id="routines-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="routines"
                id="routines-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                3. Loss of weight, appetite, strength or physical activity?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="weight"
                id="weight-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="weight"
                id="weight-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                4. Inability to dress yourself, prep meals, eat or travel?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="dress"
                id="dress-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="dress"
                id="dress-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                5. Falling, injuries or other risks at home? 
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="falling"
                id="falling-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="falling"
                id="falling-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                6. Too frequent physician or hospital visits?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="frequent"
                id="frequent-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="frequent"
                id="frequent-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                7. Medications not addressing pain or other symptoms?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="medications"
                id="medications-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="medications"
                id="medications-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
                8. Isolation from family, friends, social activities?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="isolation"
                id="isolation-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="isolation"
                id="isolation-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
              9. Paying your bills, credit card fraud or theft, life planning?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="paying"
                id="paying-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="paying"
                id="paying-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col xs="auto">
              <label className="control-label" htmlFor="vehicles">
              10. Managing medical claims and reimbursement?
              </label>
              <br />
            </Col>
            <Col xs="auto">
              <Form.Check
                className="radioButton"
                type="radio"
                label="Yes"
                name="managing"
                id="managing-yes"
                value="yes"
                ref={register}
                inline
              />
              <Form.Check
                className="radioButton"
                type="radio"
                label="No"
                name="managing"
                id="managing-no"
                value="no"
                ref={register}
                inline
              />
            </Col>
          </Form.Row>
          <Form.Row>
              <Col xs="auto">
              <p>Share your email or phone contact and our Care Team will respond to learn more:</p>
              </Col>
          </Form.Row>

          <Form.Row>
              <Col md={4}
              className="my-2"
              >
              <Form.Control 
                id="name"
                placeholder="Your Name"
                name="name"
                ref={register}
              />
              </Col>
              <Col md={4}
              className="my-2"
              >
              <Form.Control 
                id="phone"
                placeholder="Phone"
                name="phone"
                ref={register}
              />
              </Col>
              <Col md={4}
                className="my-2">
              <Form.Control 
                id="email"
                placeholder="Email"
                name="email"
                ref={register}
              />
              </Col>
          </Form.Row>

          {/* <input name="firstName" ref={register} /> */}
          <Button className="uniqueCareButton" form="assessement-form" type="submit">
          {formState.isSubmitted
            ? message
            : formState.isSubmitting
            ? "Sending..."
            : "Send"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AssessmentModal
