import React from "react"
import { Button } from "react-bootstrap"
import AssessmentModal from "./AssessmentModal"

import '@fortawesome/fontawesome-svg-core/styles.css';


function Modal() {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
    <Button
      className="uniqueCareButton"
      onClick={() => setModalShow(true)}
      >Take Assessment</Button>

      <AssessmentModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

export default Modal
