import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import AssessmentModalButton from "./AssessmentModalButton"

const Assessment = () => {
    const data = useStaticQuery(graphql` 
    query {
        assessmentImg: file(relativePath: { eq: "assessment.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 420, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
    `)
    return (
    <Container fluid className="assessmentBackground">
    <Container className="big-y-margin">
        <Row className="justify-content-sm-center h-100">
            <Col sm={4} className="my-auto">
                <Img fluid={data.assessmentImg.childImageSharp.fluid} alt="Take our assessment" className="round"/>
            </Col>
            <Col sm={6} className="my-auto text-center text-md-left">
                <h3 className="assessmentHeadline">Not sure if Symphony For Seniors provides what you need?</h3>
                <hr className="blueLine"/>
                <p className="assessmentText">Take this 2 minute assessment and see if Symphony’s solutions can simplify your life.</p>
                <AssessmentModalButton />
            </Col>
        </Row>
    </Container>
    </Container>
    )
}

export default Assessment