import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import FooterContactBackground from './FooterContactBackground'
import ContactForm from './ContactForm'


const FooterContact = () => (
    <Container fluid className="h-100 hero-container">
        <FooterContactBackground>
            <Container className="h-100">
            <Row className="h-100 justify-content-sm-center">
                <Col className="my-auto" sm={{offset: 6}} >
                <h3 className="letsConnect">Let's Connect</h3>
                <ContactForm />
                </Col>
            </Row>
            </Container>
            
        </FooterContactBackground>
    </Container>
)

export default FooterContact;